body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.premium-settlement {
  .Mui-expanded {
    margin: 10px 0 !important;
  }
  thead {
    background-color: #d6d6d6 !important;
    th{
      font-weight: bold !important;
    }
  }
  tr:nth-child(even) {
    margin-top: 10px;
    background-color:#f2f2f2 !important;
  }
}

.membership-payment {
  .MuiButton-outlined {
    color: red !important;
    border-color: red !important;
  }
  .MuiButton-outlined[disabled] {
    color: #d6d6d6 !important;
    border-color: #d6d6d6 !important;
  }
  thead {
    background-color: #d6d6d6 !important;
    th{
      font-weight: bold !important;
    }
  }
  tr:nth-child(even) {
    background-color:#f2f2f2 !important;
  }
}
