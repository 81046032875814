.App {
  min-height: 100vh;
  max-width: 100%;
  flex-wrap: wrap;
  display: flex;
  overflow-wrap: anywhere;
  color: #4b4b4b;
}

.App-link {
  color: #61dafb;
}
